"use client";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronDown } from "react-icons/fa";
import axios from "axios";
import { SESSIONSTORAGE_LANGUAGE } from "@/common/utils/constants";
import useWindowSize from "@/common/hooks/useWindowSize";
import Image from "next/image";
import { languageArr, useCookieContext } from "@/common/context/CookieProvider";
// import i18next from "i18next";
// import { useLanguage } from "@/components/common/"

export const LoginMultiLang = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { width, height } = useWindowSize();
  const { language, setLanguage } = useCookieContext();

  const getLanguage = (key: string) => {
    return languageArr.find((item) => item.key === key)?.value;
  };

  const onChangeLangHandler = async (key: string, val: string) => {
    // onChangeLang(val);
    // setUserSelectedLang(val);
    i18n.changeLanguage(key);
    setLanguage(key);
    setIsOpen(!isOpen);
  };
  const onClickClose = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-[50%] self-center text-white">
      {(width || 0) <= 768 || ((height || 0) <= 430 && (width || 0) <= 932) ? (
        <div className="relative">
          <div
            className={`flex h-[38px] w-full items-center justify-between rounded-lg border border-gray-300 bg-[#252525] px-3 py-1 text-center font-medium text-white shadow-lg ${
              (height || 0) <= 430 && (width || 0) <= 932
                ? "text-base"
                : "text-lg md:text-2xl"
            }`}
            onClick={onClickClose}
          >
            <Image
              className="mr-2 inline-block h-6 w-8"
              alt="language"
              src={
                languageArr.find((lang) => lang.key === language)?.icon || ""
              }
            />
            {getLanguage(language)}
            <FaChevronDown className="ml-2 text-base" />
          </div>
          {isOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center">
              <div
                className="absolute inset-0 bg-black/50"
                onClick={onClickClose}
              ></div>
              <div className="z-10 rounded-lg border border-gray-300 bg-[#252525] p-4 shadow-lg">
                <ul className="w-full">
                  {languageArr.map((lang) => (
                    <li
                      key={lang.key}
                      onClick={() => onChangeLangHandler(lang.key, lang.value)}
                      className="flex cursor-pointer items-center justify-between py-2 pl-4 text-start font-medium active:bg-gray-600"
                    >
                      <Image
                        alt="language"
                        className="mr-2 inline-block h-6 w-8"
                        src={lang.icon}
                      />
                      <p className="pr-10">{lang.key}</p>
                      <input
                        type="radio"
                        name="language"
                        value={lang.key}
                        checked={language === lang.key}
                        onChange={() =>
                          onChangeLangHandler(lang.key, lang.value)
                        }
                        className="form-radio mr-2"
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="relative">
          <div
            className={`flex h-[38px] w-full items-center justify-between rounded-lg border border-gray-300 bg-[#252525] px-3 py-1 text-center font-medium text-white shadow-lg ${
              (height || 0) <= 430 && (width || 0) <= 932
                ? "text-base"
                : "text-lg md:text-2xl"
            }`}
            onClick={onClickClose}
          >
            <Image
              alt="icons"
              className="mr-2 inline-block h-6 w-8"
              src={
                languageArr.find((lang) => lang.key === language)?.icon || ""
              }
            />
            {getLanguage(language)}
            <FaChevronDown className="ml-2 text-base" />
          </div>
          {isOpen && (
            <ul className="absolute z-10 w-full rounded-lg border border-gray-300 bg-[#252525] shadow-lg">
              {languageArr.map((lang) => (
                <li
                  key={lang.key}
                  onClick={() => onChangeLangHandler(lang.key, lang.value)}
                  className="flex cursor-pointer items-center rounded-lg px-4 py-1 text-lg font-medium hover:bg-gray-600 md:text-2xl"
                >
                  <Image
                    alt="Icons"
                    className="mr-2 inline-block h-6 w-8"
                    src={lang.icon}
                  />
                  {lang.value}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};
