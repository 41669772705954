"use client";
import AuthLayout from "@/components/layouts/authLayout";
import SubmitMessage from "@/components/loginSubmit/login_submit";
import "../../../common/utils/i18n";
import { useUserAgent } from "@/common/hooks/useUserAgent";
import { useEventSourceContext } from "@/common/context/EventSourceContext";
import Maintenance from "@/components/maintenance/maintenance";

const Login = () => {
  const { userAgent } = useUserAgent();
  const { activeMaintenance } = useEventSourceContext();

  return (
    <div className="h-full w-full">
      {activeMaintenance ? (
        <Maintenance />
      ) : (
        <AuthLayout>
          <div className="text-x fixed inset-0 flex items-center justify-center">
            <SubmitMessage />
          </div>
        </AuthLayout>
      )}
    </div>
  );
};

export default Login;
