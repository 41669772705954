import React from "react";
import Image from "next/image";
import LoginBackground from "@/assets/login-bg.png";

const AuthLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div
      className="fixed inset-0 bg-cover bg-center bg-no-repeat sm:bg-top"
      style={{
        backgroundImage: `url(/assets/bg_wlogo.jpg)`,
      }}
    >
      {/* <Image
        priority
        src={LG}
        alt=""
        fill
        style={{ objectFit: "cover", objectPosition: "center" }}
      /> */}
      <main>{children}</main>
    </div>
  );
};
export default AuthLayout;
